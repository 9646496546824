<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="listadoRips.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column align="left" field="radicadoFecha" header="Fecha de carga" style="min-width:10rem" bodyStyle="text-align: center">
      <template #body="{data}">
        <p class="text-xs">
            {{ $h.formatDate(data.radicadoFecha,'YYYY-MM-DD') }}
        </p>
      </template>
    </Column>
    <Column field="fechaReportada" header="Fecha Reportada" style="min-width:10rem" bodyStyle="text-align: center">
      <template #body="{data}">
        <p class="text-xs">
            {{ data.fechaReportada }}
        </p>
      </template>
    </Column>
    <Column  field="radicadoNumero" header="Numero de RIPS" style="min-width:10rem" bodyStyle="text-align: center">
      <template #body="{data}">
        <p class="text-xs">
            {{ data.radicadoNumero }}
        </p>
      </template>
    </Column>
    <Column field="valorTotal" header="Valor total" style="min-width:10rem" bodyStyle="text-align: center">
      <template #body="{data}">
        <p class="text-xs">
            {{ $h.formatCurrency(data.valorTotal) }}
        </p>
      </template>
    </Column>
    <Column header="Acciones" headerStyle="width: 8rem">
      <template #body="{data}">
        <div class="flex justify-center">
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="onRemoverow(data.id)" style="margin-right:10px" />
          <Button icon="pi pi-cloud-download" class="p-button-rounded p-button-success" @click="download(data.id)" />
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="offset"
    :rows="limit"
    :totalRecords="totalItemsCount"
    :rowsPerPageOptions="[2,10,20,30]"
    @page="onPage($event)"
  />
</div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import InformesRipsService from '../../services'
import Swal from 'sweetalert2'

export default {
  name: 'TablaListadoRips',
  setup () {
    const _informesRipsService = new InformesRipsService()
    const listadoRips = ref([])
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value
      }
    })
    const totalItemsCount = computed(
      () => listadoRips.value.count
    )
    const getLoad = (params) => {
      _informesRipsService.apiPaginate(params).then(({ data, status }) => {
        if (status === 200) {
            listadoRips.value = data
        }
      }).catch((err) => {
        console.error(err.message)
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        getLoad(params.value)
    }
    const onRemoverow = (id) => {
        Swal.fire({
            title: 'Esta seguro/a?',
            text: '¡Se eliminarán todos los registros relacionados con este RIPS y no se podrán recuperar!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
        }).then((result) => {
            _informesRipsService.onRemove(id).then(({ data, status }) => {
            if (status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: 'Archivos RIPS eliminados éxitosamente',
                    showConfirmButton: true
                }).then((result) => {
                    getLoad(params.value)
                })
            }
        }).catch((err) => {
            console.error(err.message)
        })
        })
    }
    const download = (id) => {
        _informesRipsService.download(id).then(({ data }) => {
          const host = process.env.VUE_APP_MICROSERVICE_API_CARGE_RIPS
          const url = `${host.substring(0, host.length - 15)}/${data}`
          window.open(url, '_blank')
        })
    }
    onMounted(async () => {
        getLoad()
    })
    return {
        getLoad,
        listadoRips,
        totalItemsCount,
        offset,
        page,
        limit,
        params,
        onPage,
        onRemoverow,
        download
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
